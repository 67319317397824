function lazyBackground() {
    const lazyBackgrounds = document.querySelectorAll('.lazy-background');
    if (lazyBackgrounds.length === 0) {
        return;
    }
    if ('IntersectionObserver' in window) {
        const lazyBackgroundObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                    observer.unobserve(entry.target);
                }
            });
        });
        lazyBackgrounds.forEach((el) => {
            lazyBackgroundObserver.observe(el);
        });
    }
    else {
        lazyBackgrounds.forEach((el) => {
            el.classList.add('visible');
        });
    }
}
export default lazyBackground;
